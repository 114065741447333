import { jwtDecode } from 'jwt-decode'
import moment from 'moment'
import { Logger } from './log/logger'

/**
 * 'Verify' Token - only decodes token and checks expiry - does not actually validate it with any service.
 *   Not suitable for real security check.
 * @param token
 * @returns {boolean}
 */
const checkToken = (token) => {
    if (!token) {
        return false;
    }
    const decoded = jwtDecode(token);
    const isValid = decoded.exp > Date.now() / 1000;
    Logger.debug(`Verify token: ${token}`, isValid)
    return isValid;
}

const decodeToken = (token) => {
    if (!token) {
        return false;
    }
    const decoded = jwtDecode(token);
    Logger.debug(`decode token ${JSON.stringify({
        id: decoded.id,
        'iat':decoded.iat,
        'issued_at': moment(decoded.iat * 1000).format("ddd, MMM Do YYYY, h:mm:ss a Z"),
        'expires_at': moment(decoded.exp * 1000).format("ddd, MMM Do YYYY, h:mm:ss a Z"),
    }, null, 2)}`)
    return decoded
}
const TOKEN = {
    VALID: "VALID",
    NEEDS_REFRESH: "NEEDS_REFRESH",
    EXPIRED: "EXPIRED",
    INVALID: "INVALID",
}
const checkTokenExpiry  = (token) => {
    try {
        if (!token) {
            return TOKEN.INVALID;
        }
        const decoded = decodeToken(token);
        const now = Date.now()
        const token_exp = decoded.exp * 1000
        const refresh_point = token_exp - 600000 // 10 mins before
        Logger.debug(JSON.stringify({
            now: moment(now).format("ddd, MMM Do YYYY, h:mm:ss a Z"),
            token_exp: moment(token_exp).format("ddd, MMM Do YYYY, h:mm:ss a Z"),
            refresh_point: moment(refresh_point).format("ddd, MMM Do YYYY, h:mm:ss a Z"),
        }, null, 2))
        if (now > token_exp) {
            return TOKEN.EXPIRED;
        }
        if (now > refresh_point) {
            return TOKEN.NEEDS_REFRESH;
        }
        return TOKEN.VALID
    } catch (e) {
        return TOKEN.INVALID
    }
}

export {
    checkToken,
    decodeToken,
    checkTokenExpiry,
    TOKEN
}
