
import pkg from '../package.json'
const { version } = pkg

const Config = {
    admin: {
        use_debug: false,
        context: 'admin',
        SDK_version: version,
        api_base_url: process.env.REACT_APP_SIMPLICITY_SDK_API_BASE_URL,
        http_log: process.env.REACT_APP_SIMPLICITY_HTTP_LOG === 'true' || false,
    },
    Logging: {
        SourceToken: process.env.REACT_APP_LOG_SOURCE_TOKEN,
        enableConsole: process.env.REACT_APP_ENABLE_CONSOLE_LOG === 'true' || false,
        disableCloud: process.env.REACT_APP_DISABLE_CLOUD_LOG === 'true' || false,
        enableApiHttp: process.env.REACT_APP_ENABLE_HTTP_LOG === 'true' || false,
        enableSavePipelineLog: process.env.REACT_APP_ENABLE_SAVE_PIPELINE_LOG === 'true' || false,
        enableWebpageLog: process.env.REACT_APP_ENABLE_WEBPAGE_LOG === 'true' || false,
    },
}

export { Config }
