import { Config } from '../config'
import { makeRequest } from "../common/index"
import qs from 'qs'

const base_url = Config.admin.api_base_url
const context = Config.admin.context
const endpoint = `/${context}/search`

export const reindexWebpage = async function (token, guid) {
    const method = "GET"
    let path = `${endpoint}/${guid}/index`
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const reindexWebpages = async function (token) {
    const method = "GET"
    const path = `${endpoint}/index`
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
