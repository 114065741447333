import React, { useEffect, useState } from "react";
import { SimpliCitySDK } from 'cms/sdk'
import { useAuth } from 'cms/auth/auth/hooks/useAuth'
import { useSimpliCity } from 'cms/hooks/use-simplicity'
import { RichText } from 'cms/rich-text'

const defaultTOCRichTextObjectPM = {
    "type": "doc",
    "content": [
        {
            "type": "paragraph",
            "attrs": { "textAlign": "left", "class": null },
            "content": [
                {
                    "type": "text",
                    "marks": [ { "type": "bold" } ],
                    "text": "On this page:"
                }
            ]
        }
    ]
}

const TableOfContents = (props) => {
    
    const { richtext = defaultTOCRichTextObjectPM } = props
    const { token } = useAuth()
    const { model } = useSimpliCity()
    
    const [loading,setLoading] = useState(false)
    const [chapters,setChapters] = useState([])
    
    const fetchChapters = async (token) => {
        setLoading(true)
        const { guid } = model || {}
        const toc = await SimpliCitySDK.model.toc(token, guid)
        const { chapters } = toc
        setChapters(chapters)
        setLoading(false)
    }
    useEffect(() => {
        fetchChapters(token)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token])
    
    return (
        <>
            {chapters && !loading &&
                <div id='top' className='table-of-contents'>
                    <RichText richtext={richtext} />
                    {chapters && chapters.map((item, index) => {
                        const {chapter, target} = item || {}
                        return (
                            <li key={`${target}${index}`}><a href={`#${target}`}>{chapter}</a></li>
                        )
                    })}
                </div>
            }
        </>
    )
}

TableOfContents.displayName = 'TableOfContents'
export { TableOfContents }
