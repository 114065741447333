import * as Msal from '@azure/msal-browser'
import {InteractionRequiredAuthError} from '@azure/msal-browser'
import { Logger } from '../common/log/logger'

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
const scopes = [
    'openid',          // subject populated
    'offline_access',  // allows refresh tokens
    'email',           // provides email address
    'profile',         // provides larger profile
    'User.Read',
    // encodeURIComponent(process.env.REACT_APP_SIMPLICITY_CMS_APPID),
    // encodeURIComponent(process.env.REACT_APP_AZURE_STORAGE_CONTAINER),
    // encodeURIComponent('https://storage.azure.com/.default'),
    // encodeURIComponent('https://storage.azure.com/user_impersonation'),
    // `https://${process.env.REACT_APP_STORAGE_ACCOUNT_NAME}.blob.core.windows.net`   // storage access
]

const requestAccessToken = async function (config) {

    const authApp = new Msal.PublicClientApplication(config)
    const activeAccount = authApp.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = authApp.getAllAccounts();
    const accessTokenRequest = {
        scopes,
        account: accounts[0]
    };

    if (!activeAccount && accounts.length === 0) {
        /*
        * User is not signed in. Throw error or wait for user to login.
        * Do not attempt to log a user in, outside of the context of MsalProvider
        */
        // const authResult = await authApp.acquireTokenInteractive(request);
    }

    authApp.acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
            // Acquire token silent success
            const accessToken = accessTokenResponse.accessToken
            Logger.log(`aquire silent token ${accessToken}`,accessToken)
            return accessToken
        })
        .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                authApp.acquireTokenPopup(accessTokenRequest)
                    .then((accessTokenResponse) => {
                        // Acquire token silent success
                        const accessToken = accessTokenResponse.accessToken
                        Logger.error('interactive',accessToken)
                        Looger.flush
                        return accessToken
                    })
            }
            Logger.error(error)
        });
}

export {
    requestAccessToken
}