import React from "react";
import { Button } from "@mui/material";
import { Config } from "config";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc.js";
dayjs.extend(utc);
import { getFields, getValue } from "cms/utils/values-settings";
import { RichTextSimple } from "cms/rich-text/rich-text-simple";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "icons";
const { faXmark, faTriangleExclamation } = solid;

export const LiveAlertBanner = (props) => {
    const {
        alert,
        enabled,
        // alertLoaded,
        showBanner,
        handleCloseBanner,
        onClickBanner,
    } = props;

    if (showBanner && enabled) {
        const { model, webpage } = alert || {};
        const { title, updated_at } = model || {};
        const { path } = webpage || {};
        const fields = getFields(model);
        const { summary } = fields;

        // Get the value of the Summary directly, avoiding useSimplicity (the alert is not regular webpage/model of this page).
        const summaryValue = getValue(summary);
        const date = dayjs.utc(updated_at).local().format(Config.dates.date_short_no_year);
        const time = dayjs.utc(updated_at).local().format(Config.dates.time);

        const handleClickBanner = (event) => {
            onClickBanner(event, path);
        };

        return (
            <div className={`alert-banner ${showBanner !== true ? "hide" : ""}`}>
                <span className="alert-banner-icon">
                    <FontAwesomeIcon icon={faTriangleExclamation} size="2xl" />
                </span>
                <div className="alert-banner-container">
                    <div className="alert-banner-content">
                        <div className="alert-banner-header">
                            <div className="alert-banner-header-text">
                                <span className="alert-banner-title">{title}</span>
                                <span className="alert-banner-time-stamp">
                                    Updated {date} - {time}
                                </span>
                            </div>
                            <div className="btn-wrapper">
                                <button className="btn-close" onClick={handleCloseBanner}>
                                    <FontAwesomeIcon icon={faXmark} size="1x" />
                                </button>
                            </div>
                        </div>

                        <RichTextSimple richtext={summaryValue} />
                        <div className="alert-banner-actions">
                            <Button className="LearnMore" onClick={handleClickBanner}>
                                Learn more
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
};
