import Package from '../../package.json'

export const getVersion = () => {
    const version  = Package.version
    return version
}
export const getPackageNameName = () => {
    const name  = Package.name
    return name
}
