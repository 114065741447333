import React, { useState } from 'react'
import { ResultItem } from 'cms/shared/feeds/calendar/result-item'
import { SearchFilterBar } from 'cms/shared/feeds/search-filter-bar'
import { EventCalendar } from 'cms/shared/event-calendar/calendar'
import { Tabs } from 'components/tabs'
import { TabContent } from 'components/tabs/tab-content'
import { useCategoryInfo } from 'cms/hooks/use-category-info'
import { useFetchEventFeed } from 'cms/shared/feeds/hooks/use-fetch-event-feed'
import { FeedPagination } from 'cms/shared/feeds/pagination/feed-pagination'

const CalendarFeedFilter = () => {
    
    const tabs = [
        {id:0, text: 'Calendar'},
        {id:1, text: 'List'},
    ]
    const [selected, setSelected] = useState(0)
    const isCalender = selected === 0
    const chooseSelected = (id) => {
        setSelected(id)
    }
    // get categories based on 'model.event', meetings share the same ones
    const type = 'model.event'
    const selectedCategory = undefined
    const { categories, categoryName, filterName } = useCategoryInfo(type)
    
    const {
        loading,
        value,
        pagination,
        search
    } = useFetchEventFeed('all', categoryName, selectedCategory, isCalender)
    
    const { first, last, hasResults, total, } = pagination.state
    
    const { models } = value || {}
    
    return (
        <div className='calendar'>
            
            <SearchFilterBar
                // isCalender={isCalender}
                categories={categories}
                filterName={filterName}
                runSearch={search}
            />
            
            {loading &&
                <p>Loading...</p>
            }
            {isCalender && !loading &&
                <p>&nbsp;</p>
            }
            {!isCalender && !loading && hasResults &&
                <p>{first} to {last} of {total} results.</p>
            }
            {!isCalender && !loading && !hasResults &&
                <p>{total} results.</p>
            }
            
            <Tabs
                tabs={tabs}
                selected={selected}
                setSelected={chooseSelected}
            >
                
                {/*--------------   Calendar List    -------------------*/}
                <TabContent
                    id={0}
                    tab={tabs[0]}
                    selected={selected}
                >
                    <div style={{
                        display: "flex", flexDirection: "column",
                        justifyContent: "center",
                        alignItems: 'center',
                        width: '100%',
                    }}>
                        
                        <EventCalendar
                            events={models}
                        />
                        
                    </div>
                </TabContent>
                
                
                {/*--------------   Results List    -------------------*/}
                <TabContent
                    id={1}
                    tab={tabs[1]}
                    selected={selected}
                >
                    {/*<CalendarFeedFilter type={'model.meeting'} />*/}
                    <div className="template-wrap">
                        <div style={{
                            display: "flex", flexDirection: "column",
                            justifyContent: "center",
                        }}>
                            {models && models.map((model, index) => {
                                const { Id } = model
                                return (
                                    <ResultItem key={`${Id}${index}`} model={model} />
                                )
                            })}
                            
                            <FeedPagination
                                pagination={pagination}
                            />
                        
                        </div>
                    </div>
                
                </TabContent>
            
            </Tabs>
        
        </div>
    )
}

export { CalendarFeedFilter }
