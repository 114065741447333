import { Backdrop } from '@mui/material'
import cn from 'clsx'
import { Configure, InstantSearch } from 'react-instantsearch'
import { FaWrapper } from '../fa-wrapper'
import { QuickSearchInput } from '../input/quick-search-input'
import { useAlgolia } from 'cms/search/hooks/use-algolia'
import { CompactResults } from './results/compact-results'
import { useQuickSearch } from './use-quick-search'

export function QuickSearch () {
    const { algoliaClient, routing, searchIndex } = useAlgolia()
    const { 
        backDropId,
        closeOnAnchorClick,
        closeOnOverlayFocus,
        inputRef,
        onInputFocus,
        showResults,
        showSearchInput,
        toggleSearchInput,
    } = useQuickSearch()
    
    const cssQuickSearch = cn(
        'quick-search-container',
        { 'quick-search--visible' : showSearchInput }
    )
    
    return (
        <InstantSearch
            searchClient={algoliaClient}
            indexName={searchIndex}
            routing={routing}
            insights
            future={{
                preserveSharedStateOnUnmount: true,
            }}
        >
            <Configure 
                hitsPerPage={5}
                filters='is_document:false'
                // this prevents algolia from forcing these values and sending another request
                highlightPreTag='__ais-highlight__'
                highlightPostTag='__/ais-highlight__'
            />
            <div onClick={closeOnAnchorClick}>
                
                <div id="search-container" className={cssQuickSearch}>
                    
                    {/*  shown on bigger screens  */}
                    <QuickSearchInput
                        ref={inputRef}
                        onChange={onInputFocus}
                        onFocus={onInputFocus}
                        navigateOnSubmit
                        autoSearch
                    >
                        <FaWrapper icon="fa-regular fa-magnifying-glass" fontSize="18px" show />
                    </QuickSearchInput>
                    
                    {/*  shown on mobile */}
                    <button
                        type="button"
                        className={`quick-search-submit`}
                        aria-label="Toggle the search button"
                        onClick={toggleSearchInput}
                    >
                        <FaWrapper icon="fa-regular fa-magnifying-glass" fontSize="18px" show />
                    </button>

                    {showResults && (
                        <div style={{ position: 'relative' }}>
                            <CompactResults />
                        </div>
                    )}
                </div>

                <Backdrop
                    id={backDropId}
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={showResults}
                    transitionDuration={0}
                    onClick={closeOnOverlayFocus}
                />

            </div>

        </InstantSearch>
    )
}
