import axios from 'axios'
import { Config } from '../config'
import { Logger, blackBright, redBright } from './log/logger'

const makeRequest = async function (method, baseUrl, path, headers, payload ) {
    // Logger.debug("PAYLOAD", payload)
    const request_url = new URL(path, baseUrl).toString()
    // Logger.debug('make api request with headers: '  + JSON.stringify(headers))
    const options = {
        method,
        url: request_url,
        headers,
        data: payload,
        timeout: 15000,
        responseType: 'json',  // TODO: NOTE: may need to avoid once in a while
        // rejectUnauthorized: false,
    }
    // Logger.debug(
    //     blackBright(`Making request for ${method} to api ${path}`),
    //     {baseUrl, path, options, payload}
    // )
    return axios(options)
        .then(function (response) {
            const request = response?.request
            const data = response?.data
            const status = response?.status
            Logger.debug(
                blackBright(`Request: [${status}] [${method.toUpperCase()}] ${path}`),
                { baseUrl, path }
                // {baseUrl, path, options, payload, data}
                // {baseUrl, path, options, payload, request, response}
            )
            if (response?.status > 199 && response?.status < 300) {
                if (Config.admin.http_log) {
                    Logger.debug(`${response.status} ${response?.statusText} ${method} ${request_url}`, { data })
                }
                return response?.data
            }
            else {
                Logger.error(
                    `API Request - No data returned: ${response?.status} ${response?.statusText} ${request_url} '${response?.data}'`,
                    {response}
                )
                return response?.data
            }
        })
        .catch(function (error) {
            const status = error?.code
            const error_message = redBright(`Request Error: [${status}] [${method.toUpperCase()}] ${path} ${error?.message}`)
            Logger.error(error_message, {error})
            if (error?.response?.status === 401) {
                // token not valid or expired
                // throw Error("401 Unauthorized Error")
                Logger.error(`401 Unauthorized Error`)
                // TODO: need to reimplement this
                // window.location.href = '/logout'

            } else {
                throw error
            }
        })
    // .finally(function () {
    //     // Logger.flush()
    // })
}
export { makeRequest }