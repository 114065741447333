import { Config } from '../config'
import { makeRequest } from '../common/index'
import qs from 'qs'

const base_url = Config.admin.api_base_url
const context = Config.admin.context
const endpoint = `/${context}/user`

const getUsers = async function (token,params) {
    const method = 'GET'
    let path = `/${context}/users`
    if (params) {
        const page = params.page
        const pageSize = params.pageSize
        const query = qs.stringify(
            { pagination: { page, pageSize } }, { encodeValuesOnly: true }
        )
        path = `${path}?${query}`
    }
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
const searchUsers = async function (token,searchTerm,params) {
    const method = 'GET'
    let path = `${endpoint}/search`
    if (params) {
        const page = params.page
        const pageSize = params.pageSize
        const query = qs.stringify(
            {
                term: searchTerm,
                pagination: { page, pageSize }
            },
            { encodeValuesOnly: true }
        )
        path = `${path}?${query}`
    }
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
const getUser = async function (token, guid) {
    const method = 'GET'
    const path = `${endpoint}/${guid}`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}

// TODO: ensure additional security
const createUser = async function (token, payload) {
    const method = 'POST'
    const path = `${endpoint}`
    const headers = {"Authorization": `Bearer ${token}`}
    return await makeRequest(method, base_url, path, headers, payload)
}
// TODO: ensure additional security
const updateUser = async function (token, guid, payload) {
    const method = 'PATCH'
    const path = `${endpoint}/${guid}`
    const headers = {"Authorization": `Bearer ${token}`}
    return await makeRequest(method, base_url, path, headers, payload)
}
const setUserRole = async function (token, guid, role) {
    const method = 'PATCH'
    const path = `${endpoint}/${guid}/role`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = { role }
    return await makeRequest(method, base_url, path, headers, payload)
}
// TODO: ensure additional security
const deleteUser = async function (token, guid) {
    const method = 'DELETE'
    const path = `${endpoint}/${guid}`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}

export {
    getUsers,
    getUser,
    searchUsers,
    createUser,
    updateUser,
    deleteUser,
    setUserRole,
}
